import environment from '@/environment';
import { useMemo } from 'react';

export default function useShortID() {
  const shortID = useMemo(() => {
    const isAppsite = location.pathname.split('/').find((p) => p.match(/^[a-f0-9]{8}$/i));

    const isDSBContent = ['dsb-content', 'announcements'].some((p) => location.pathname.includes(p));

    if (!isAppsite && isDSBContent) {
      return environment.dsbShortID;
    }

    return isAppsite ?? null;
  }, [location.pathname]);

  return shortID;
}
