import { OMTContext } from '@/hooks/onlineMembershipTemplates/omtContext';
import useAccounts from '@/hooks/useAccounts';
import useFeedback from '@/hooks/useFeedback';
import useSdk from '@/hooks/useSdk';
import useUser from '@/hooks/useUser';
import { friendlyBytes } from '@/routes/AssetManager/AssetSidebar';
import { classNames } from '@/util/classNames';
import dayjs from '@/util/dayjs';
import { ChatBubbleLeftIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { ChatBubbleLeftEllipsisIcon, CogIcon, EnvelopeIcon, PaperClipIcon, TrashIcon } from '@heroicons/react/24/solid';
import AccountResource from 'ec.sdk/lib/resources/accounts/AccountResource';
import AssetResource from 'ec.sdk/lib/resources/datamanager/AssetResource';
import EntryResource from 'ec.sdk/lib/resources/publicAPI/EntryResource';
import React, { useContext, useRef, useState } from 'react';
import { Link } from 'react-router';
import useSWR, { mutate } from 'swr';
import Alert from './Alert';
import Button from './Button';

export type Comment = {
  id: string;
  text: string;
  files?: Array<AssetResource>;
  metadata?: any;
  created: Date;
  creator: AccountResource;
};

export const InternalComments = ({
  parent,
  filter: filterItems,
}: {
  parent: EntryResource & { comments: Comment[] };
  filter?: () => boolean;
}) => {
  const files = useRef<HTMLInputElement>(null);
  const [comment, setComment] = useState<string>('');
  const [assets, setAssets] = useState<any>([]);
  const { withFeedback, pending } = useFeedback();
  const { api } = useSdk();

  const comments = parent?.comments?.sort((b, a) => dayjs(a.created).unix() - dayjs(b.created).unix()) ?? [];

  const onSubmit = () => {
    withFeedback(
      async () => {
        let uploaded = [];
        if (assets.length > 0) {
          const formData: FormData = new FormData();
          for (const file of assets) {
            formData.append('file', file, file.name);
          }
          formData.set('ignoreDuplicates', 'true');
          ({ items: uploaded } = (await api.createDMAssets('comments', formData)) as any);
        }

        const newComment = await api.createEntry('comment', {
          text: comment,
          files: uploaded,
        });
        parent.comments.push(newComment as any);
        await parent.save();
        setAssets([]);
        setComment('');
      },
      { success: 'Kommentar gespeichert', error: 'Kommentar konnte nicht gespeichert werden' },
    );
  };

  return (
    <div className="bg-white dark:bg-gray-700 rounded-lg p-4">
      <div className="mb-6 pb-6 border-b">
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Kommentar hinzufügen"
          className={`block w-full shadow-sm mb-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 dark:border-gray-500 rounded-md dark:bg-gray-700`}
        />
        <input
          ref={files}
          className="hidden"
          type="file"
          multiple
          onChange={({ target }) => {
            const { files: f } = target;
            setAssets(Array.from(f));
            target.value = '';
          }}
        />
        {assets?.map((asset, index) => (
          <div className="flex items-center space-x-3 mb-2.5">
            <div className="flex-shrink-0 h-10 w-10 rounded-full bg-slate-50 dark:bg-slate-800 flex items-center justify-center">
              <PaperClipIcon className="w-5 h-5" />
            </div>
            <div className="min-w-0 flex-1">
              <div className="text-sm font-medium text-gray-900 dark:text-gray-300">{asset.name}</div>
              <p className="mt-0.5 text-xs text-gray-500">{friendlyBytes(asset.size)}</p>
            </div>
            <div className="flex-shrink-0 self-center flex">
              <button
                onClick={() => setAssets((old) => old.slice(0, index).concat(old.slice(index + 1)))}
                type="button"
                className="relative inline-flex items-center p-2 border text-sm font-medium rounded-md text-red-500 border-red-500"
              >
                <TrashIcon className="w-4 h-4" />
              </button>
            </div>
          </div>
        ))}
        <div className="flex justify-end mt-4 space-x-2">
          <Button $disabled={pending} onClick={() => files.current.click()}>
            Dateien hinzufügen
          </Button>
          <Button $primary loading={pending} onClick={onSubmit}>
            Senden
          </Button>
        </div>
      </div>
      <div className="">
        <div ref={(ref) => ref?.scrollTo(0, ref?.scrollHeight)} className="flow-root max-h-[800px] overflow-y-auto">
          <ul role="list" className="">
            {comments?.length > 0 ? (
              comments
                ?.filter((c) => (filterItems ? filterItems(c) : true))
                .map((comment, index) => (
                  <li key={comment.id}>
                    <div className="relative pb-6">
                      {index !== comments.length - 1 ? (
                        <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                      ) : null}
                      <div className="relative flex items-start space-x-3">
                        <CommentPill comment={comment as any} />
                      </div>
                    </div>
                  </li>
                ))
            ) : (
              <Alert type="info">noch keine Kommentare vorhanden</Alert>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export const CommentPill = ({ comment, onChange }: { comment: Comment & EntryResource; onChange?: Function }) => {
  const { withFeedback, pending } = useFeedback();
  const { data: accounts } = useAccounts();
  const { data: accountID } = useSWR(comment.id, async () => {
    const {
      items: [
        {
          user: { accountID: id },
        },
      ],
    } = await comment.getEvents();
    return id;
  });

  const { data: creator } = useSWR(`account/${accountID}`, () => accounts.account(accountID));

  const user = useUser();
  if (!comment) return null;
  const isSystem = comment.text?.startsWith('{system}');
  const isFeedback = comment.text?.startsWith('{feedback}');
  const textContent = comment.text?.replace(/{system}|\{feedback\} /g, '');

  return (
    <>
      <div className="relative">
        {isSystem ? (
          <div className=" ml-1 flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white dark:ring-gray-700">
            <CogIcon className="h-5 w-5 dark:text-gray-400 text-gray-500" aria-hidden="true" />
          </div>
        ) : isFeedback ? (
          <div className=" ml-1 flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white dark:ring-gray-700">
            <ChatBubbleLeftIcon className="h-5 w-5 dark:text-gray-400 text-gray-500" aria-hidden="true" />
          </div>
        ) : (
          <>
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 dark:bg-gray-400 text-lg text-white ring-8 ring-white dark:ring-gray-700">{`${
              creator?.name.charAt(0).toUpperCase() || ''
            }`}</div>
            <span className="absolute -bottom-0.5 -right-1 rounded-tl px-0.5 py-px">
              <ChatBubbleLeftEllipsisIcon
                className="h-5 w-5 text-gray-400 dark:text-gray-200 drop-shadow-lg fill-white"
                aria-hidden="true"
              />
            </span>
          </>
        )}
      </div>
      <div className="min-w-0 flex-1">
        <div>
          <div className={classNames('text-sm leading-4', creator?.email && 'cursor-context-menu')}>
            <div className="font-medium text-gray-900 dark:text-gray-300 group flex gap-3 items-center">
              {creator?.name || (
                <div className="w-32 h-3.5 bg-gray-200 dark dark:bg-gray-600 rounded-lg animate-pulse" />
              )}
              {creator?.email && (
                <>
                  {user.email === creator.email && onChange && (
                    <TrashIcon
                      className="w-4 h-4 group-hover:opacity-100 opacity-0 text-gray-500"
                      onClick={async () => {
                        withFeedback(
                          async () => {
                            await comment.delete();
                            onChange?.();
                          },
                          { success: 'Erfolgreich gelöscht', error: 'Konnte nicht gelöscht werden' },
                        );
                      }}
                    />
                  )}
                  <a href={`mailto:${creator.email}`}>
                    <EnvelopeIcon className="w-4 h-4 group-hover:opacity-100 opacity-0 text-gray-500" />
                  </a>
                </>
              )}
            </div>
          </div>
          <p className="text-[11px] text-gray-500">{dayjs(comment._created).format('DD.MM.YY HH:m')}</p>
        </div>
        <div
          className="mt-2 text-sm text-gray-600 dark:text-gray-300"
          dangerouslySetInnerHTML={{ __html: textContent }}
        />

        {comment.metadata?.fromVersion && (
          <small>
            <Link target="_blank" to={'./../' + comment.metadata?.fromVersion}>
              Zur Original Version
            </Link>
          </small>
        )}

        {comment.metadata?.testResults && (
          <div className="mt-2 bg-slate-900 text-white p-3 rounded-lg font-mono whitespace-pre text-xs">
            {JSON.stringify(comment.metadata.testResults, null, 2)}
          </div>
        )}
        {comment.files?.length > 0 && (
          <div className="mt-2 dark:bg-slate-600 bg-slate-100 p-3 pb-1 rounded-lg">
            <ul role="list" className="flex flex-wrap">
              {comment.files.map((file) => {
                const fileName = new URL(file.file.url).pathname.split('/').pop();
                return (
                  <li key={file.assetID} className="flex-shrink-0 mr-2 mb-2">
                    <a
                      target="_blank"
                      href={file.file.url}
                      download={fileName}
                      className="inline-flex items-center space-x-1"
                    >
                      <PaperClipIcon className="h-3 w-3 text-gray-400 dark:text-gray-200" aria-hidden="true" />
                      <span className="text-xs font-medium text-gray-900 dark:text-gray-300">{fileName}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {isFeedback && (
          <Button
            className="mt-3 "
            disabled={pending}
            onClick={() => {
              withFeedback(
                async () => {
                  if (!comment.metadata.done) {
                    comment.metadata.done = user.email;
                  } else {
                    comment.metadata.done = false;
                  }
                  await comment.save();
                  await mutate((key) => typeof key === 'string' && key.startsWith('omt/'), undefined, {
                    revalidate: true,
                  });
                },
                { success: 'Erfolgreich gespeichert', error: 'Konnte nicht gespeichert werden' },
              );
            }}
          >
            <CheckCircleIcon
              className={classNames('w-5 h-5  mr-3', comment.metadata.done ? 'text-green-500' : 'text-gray-500')}
              aria-hidden="true"
            />{' '}
            Feedback als {comment.metadata.done ? 'nicht erledigt ' : 'erledigt '} markieren
          </Button>
        )}
      </div>
    </>
  );
};
