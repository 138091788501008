import React, { Fragment, ReactNode, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router';
import { classNames } from '../util/classNames';
import Card from './Card';

const Tab = ({ children, className }: { children: ReactNode; className?: string }) => {
  return <Card className={classNames('w-full', className, window.HO_EMBED && '!bg-[#f3f3fa] ')}>{children}</Card>;
};

Tab.Head = ({ children, className = '' }) => {
  return (
    <div
      ref={(e) => {
        if (e) e.style.top = e.offsetHeight + e.clientHeight / 2 + 'px';
      }}
      className={classNames(
        'text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700',
        className,
        window.HO_EMBED && 'sticky top-30 z-30 pt-1 bg-[#f3f3fa]',
      )}
    >
      <ul className="flex flex-wrap">{children}</ul>
    </div>
  );
};

Tab.Content = ({ children }) => {
  return (
    <Card.Body className={classNames(window.HO_EMBED && '!p-0')}>
      <div className="mt-4">{children}</div>
    </Card.Body>
  );
};

interface TabHeaderProps {
  title: string | ReactNode;
  active: boolean;
  onClick: () => void;
  icon?: React.ReactNode;
}

Tab.Header = ({ title, active, onClick, icon }: TabHeaderProps) => {
  const tabsClass = {
    default: 'hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300',
    active: 'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500 border-b-2',
  };

  return (
    <li id={`uf-${title}`} className="mr-2">
      <div
        onClick={onClick}
        className={`inline-flex items-center gap-2 p-4 cursor-pointer rounded-t-lg whitespace-nowrap ${
          active ? tabsClass.active : tabsClass.default
        }`}
      >
        {icon} {title}
      </div>
    </li>
  );
};

interface TabNavProps {
  onChange?: (tab: string) => void;
  className?: string;
  tabs: {
    [key: string]: {
      title: string;
      disabled?: boolean;
      icon: React.ForwardedRef<any>;
      content: React.ReactNode | null;
    };
  };
}

export const TabNav: React.FC<TabNavProps> = ({ onChange, className, tabs }) => {
  const tabsArray = Object.entries(tabs);
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tab');

  useEffect(() => {
    if (!activeTab || activeTab === 'undefined') {
      if (tabsArray.length) {
        const first = tabsArray.findIndex(([, value]) => !value.disabled);
        setSearchParams((params) => {
          params.set('tab', tabsArray[first][0]);
          return params;
        });
      }
    }
  }, [tabs, tabsArray.map(([, value]) => value.disabled)]);

  return (
    <Tab className={className}>
      <Tab.Head>
        {tabsArray.map(
          ([key, values]) =>
            !values.disabled &&
            (values.content ? (
              <Link key={key} to={`?tab=${key}`}>
                <Tab.Header
                  key={key}
                  //@ts-ignore
                  icon={values.icon && <values.icon className="w-4 h-4" />}
                  title={values.title}
                  onClick={() => onChange?.(key)}
                  active={activeTab === key}
                />
              </Link>
            ) : (
              <Tab.Header
                key={key}
                //@ts-ignore
                icon={values.icon && <values.icon className="w-4 h-4" />}
                title={values.title}
                onClick={() => onChange?.(key)}
                active={activeTab === key}
              />
            )),
        )}
      </Tab.Head>
      <Tab.Content>
        {tabsArray.map(([key, values]) => activeTab === key && <Fragment key={key}>{values.content}</Fragment>)}
      </Tab.Content>
    </Tab>
  );
};

export default Tab;
