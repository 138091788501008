import { PublicAPI } from 'ec.sdk';
import { environment as Environment, options as Options } from 'ec.sdk/lib/Core';

import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import useSWRImmutable from 'swr/immutable';
import environment from '../environment';
import useAccounts from './useAccounts';
import useShortID from './useShortID';

export interface UsePublicAPIProps {
  shortID: string;
  env?: Options | Environment;
  swrOptions?: any;
}

export function usePublicAPI(props: UsePublicAPIProps) {
  const { shortID, env, swrOptions } = props;

  const res = useSWRImmutable(
    shortID ? [shortID, env] : null,
    () => {
      console.log('resolving PublicAPI');
      const api = new PublicAPI(shortID, env, true);
      api.setClientID('rest');

      return api.resolve();
    },
    swrOptions,
  );
  useEffect(() => {
    res.error && console.error('error resolving PublicAPI', res.error);
  }, [res.error]);
  /* useEffect(() => {
    res.data && console.log('PublicAPI resolved', res.data);
  }, [res.data]); */
  return res;
}

/* export const PublicAPIProvider = (props: any) => {
  const { children, shortID, env = environment.env } = props;
  const { data: api } = usePublicAPI({ shortID, env });
  return <PublicAPIContext.Provider value={api}>{children}</PublicAPIContext.Provider>;
}; */

/* [markdown]
 * Exposes current sdk PublicAPI instance (.api) + legacy angular sdk (.sdk) instance.
 * ```js
 * const { api } = useSdk();
 * ```
 */

function useSdk() {
  const { pathname } = useLocation(); // make sure useSdk is always called inside BrowserRouter
  const isAppsite = pathname?.split('/')?.[1]?.match(/^[a-f0-9]{8}$/i);
  const shortID = useShortID();

  const { shortID: sId } = useParams();
  if (sId && sId !== shortID && !pathname.includes('/signup')) {
    console.warn('Seems like you are using a non existing shortID ', sId);
    window.location.href = '/';
  }

  useEffect(() => {
    if (shortID) {
      Sentry.setContext('appsite', {
        id: shortID,
      });
    }
  }, [shortID]);

  const {
    data: api,
    error,
    isLoading,
  } = usePublicAPI({
    shortID,
    env: environment.env,
    swrOptions: {
      retryOnError: false,
      onError: (err) => {
        console.warn(`error loading public api with shortID "${shortID}"`, err);
      },
    },
  });

  const apiResolved = !isLoading && !!api;
  const { data: accounts } = useAccounts();
  return { api, isAppsite, apiResolved, accounts };
}
// test
export default useSdk;
