import { OMTContext } from '@/hooks/onlineMembershipTemplates/omtContext';
import { useConsentCampaings } from '@/hooks/useConsentCampaings';
import React, { useContext, useEffect } from 'react';
import SimpleSelect from '../SimpleSelect';
import { useForm, useFormContext } from 'react-hook-form';
import Button from '../Button';
import { ArrowTopRightOnSquareIcon, ArrowUpLeftIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import Input from '../Input';
import { translate } from '@/util/translate';
import Form from '../Form';
import useFeedback from '@/hooks/useFeedback';
import Badge from '../Badge';

export default function ConsentSelect() {
  const { data: consents } = useConsentCampaings({
    filter: {
      type: { search: 'bookContract' },
    },
  });
  const { selectedVersion } = useContext(OMTContext);

  const form = useForm({
    defaultValues: { ...selectedVersion } as any,
  });

  useEffect(() => {
    form.reset({ ...selectedVersion });
  }, [selectedVersion, form]);

  const { withFeedback, pending } = useFeedback();

  if (!consents) {
    return null;
  }

  const onSubmit = (values) => {
    withFeedback(
      async () => {
        selectedVersion.consents = values.consents;
        await selectedVersion.save();
      },
      {
        success: 'Erfolgreich gespeichert',
        error: (e) => 'Fehler beim Speichern' + JSON.stringify(e),
      },
    );
  };

  return (
    <div>
      <div className="flex flex-col gap-3">
        <Form.Item.SortableList
          form={form}
          name="consents"
          appendLabel="Konsent hinzufügen"
          extraAction={
            <SimpleSelect
              placeholder="Zustimmung hinzufügen"
              onChange={(value) => {
                form.setValue('consents', [...form.watch('consents'), value], {
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              items={consents?.items?.map((consent) => ({
                label: (
                  <p>
                    {consent.name}{' '}
                    <small className="p-1 bg-gray-200 rounded-md">
                      {translate(consent.type, {
                        bookContractCheckbox: 'Checkbox',
                        bookContractAge: 'Alter',
                        bookContract: 'Allgemein',
                      })}
                    </small>
                  </p>
                ),
                value: consent,
              }))}
            />
          }
        >
          {(i) => {
            const consent = form.watch('consents.' + i);
            return (
              <div>
                <div className="flex  items-center w-full">
                  <div className="font-bold">{consent.name} </div>
                  <Button.Action
                    onClick={() => {
                      // open in new tab
                      window.open(`../../membership/consents/${consent.id}`, '_blank');
                    }}
                    tooltip="Konfiguration ansehen"
                  >
                    <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                  </Button.Action>
                  <Badge>
                    {translate(consent.type, {
                      bookContract: 'Standard',
                      bookContractAge: 'Alter',
                      bookContractCheckbox: 'Checkbox',
                    })}
                  </Badge>
                </div>
                <Form.Item $first $dense>
                  <Form.Item.Label>Was soll hier überschrieben werden?</Form.Item.Label>
                  <Input name={`consentSettings.${i}.test`} control={form.control} />
                </Form.Item>
                <Form.Item $first $dense>
                  <Form.Item.Label>oder hier?</Form.Item.Label>
                  <Input name={`consentSettings.${i}.test`} control={form.control} />
                </Form.Item>
              </div>
            );
          }}
        </Form.Item.SortableList>
      </div>

      <div className="flex p-4 pb-6 bg-white dark:bg-gray-700 sticky bottom-0 justify-end">
        <Button $disabled={!form.formState.isDirty} loading={pending} $primary onClick={form.handleSubmit(onSubmit)}>
          Speichern
        </Button>
      </div>
    </div>
  );
}
